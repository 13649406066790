import './bootstrap';
import { createApp, h } from 'vue/dist/vue.esm-bundler';
import LandingPage from "./components/Home/LandingPageComponent.vue";
import vSelect from 'vue-select';

vSelect.props.components.default = () => ({
    // Deselect: {
    //     render: () => h('span', '❌'),
    // },
    OpenIndicator: {
        render: () => h('span', '⌵'),
    },
});

const app = createApp({
    components: {
        LandingPage
    }
})
app.component('vSelect', vSelect);
app.mount('#app-content');
