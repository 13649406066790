<template>
    <!-- Pre-loader-start -->
    <div id="preloader">
        <div class="tg-cube-grid">
            <div class="tg-cube tg-cube1"></div>
            <div class="tg-cube tg-cube2"></div>
            <div class="tg-cube tg-cube3"></div>
            <div class="tg-cube tg-cube4"></div>
            <div class="tg-cube tg-cube5"></div>
            <div class="tg-cube tg-cube6"></div>
            <div class="tg-cube tg-cube7"></div>
            <div class="tg-cube tg-cube8"></div>
            <div class="tg-cube tg-cube9"></div>
        </div>
    </div>
    <!-- Pre-loader-end -->

    <!-- Scroll-top -->
    <button class="scroll-top scroll-to-target scroll-top-two" aria-label="Скролл вверх" data-target="html">
        <i class="fas fa-angle-up"></i>
    </button>
    <!-- Scroll-top-end-->

    <!-- header-area -->
    <header id="home">
        <div id="header-fixed-height"></div>
        <div id="sticky-header" class="tg-menu-area menu-area">
            <div class="container">
                <div class="row">
                    <div class="col-12">
                        <div class="mobile-nav-toggler"><i class="flaticon-layout"></i></div>
                        <div class="menu-wrap">
                            <nav class="menu-nav">
                                <div class="logo">
                                    <a href="/">
<!--                                        <h5 class="fw-bold opacity-75 pt-3 pt-sm-1" style="color: #66b021">TOP BRIKET <span class="text-lowercase fs-6">by</span> <span class="fs-6">Coswick</span></h5>-->
                                        <img :src="'assets/img/logo/briket-logo7.webp'" width="160" alt="Logo topbriket">
                                    </a>
                                </div>
                                <div class="navbar-wrap main-menu d-none d-xl-flex">
                                    <ul class="navigation">
                                        <li><a href="#home" class="section-link">ГЛАВНАЯ</a></li>
                                        <li><a href="#benefits" class="section-link">ПРЕИМУЩЕСТВА</a></li>
<!--                                        <li><a href="#equipment" class="section-link">ОБОРУДОВАНИЕ</a></li>-->
                                        <li><a href="#production" class="section-link">ПРОИЗВОДСТВО</a></li>
                                        <li><a href="#shop" class="section-link">БРИКЕТЫ</a></li>
                                        <li><a href="#reviews" class="section-link">Отзывы</a></li>
                                        <li><a href="#delivery" class="section-link">Оплата / Доставка</a></li>
                                        <li class="menu-item-has-children"><a href="javascript:void(0)" class="section-link">Контакты</a>
                                            <ul class="sub-menu">
                                                <li><a href="tel:+375175432030">+375 (17) 543-20-30</a></li>
                                                <li><a href="tel:+375293538931">+375 (29) 353-89-31</a></li>
                                            </ul>
                                        </li>
                                    </ul>
                                </div>
                                <div class="header-btn d-none d-sm-block offCanvas-btn">
                                    <a href="#shop" @click="formTop" class="section-link tg-btn navSidebar-button"><span class="d-xl-none d-xxl-block">Сделать заказ</span><span class="d-none d-xl-block d-xxl-none"><i class="flaticon-shopping-cart-1 fs-5"></i></span></a>
                                </div>
                            </nav>
                        </div>
                        <!-- Mobile Menu  -->
                        <div class="mobile-menu">
                            <nav class="menu-box">
                                <div class="close-btn"><i class="fas fa-times"></i></div>
                                <div class="nav-logo">
                                    <img :src="'assets/img/logo/briket-logo7.webp'" style="width: 210px" alt="">
                                </div>
                                <div class="menu-outer">
                                    <!--Here Menu Will Come Automatically Via Javascript / Same Menu as in Header-->
                                </div>
                            </nav>
                        </div>
                        <div class="menu-backdrop"></div>
                        <!-- End Mobile Menu -->
                    </div>
                </div>
            </div>
        </div>
        <!-- offCanvas-start -->
        <div id="createdForm" class="offCanvas-wrap">
            <div ref="form_order" class="offCanvas-toggle"><img :src="'assets/img/icons/close.png'" alt="icon"></div>
            <div class="offCanvas-body">
                <div class="offCanvas-content mb-0">
                    <h3 @click="formTop" class="title text-center mb-0">Сделать <span>заказ</span></h3>
                    <form id="contact-form" class="contact-form pt-4" data-toggle="validator">
                    <div class="row">
                        <div class="col-12 mb-3 order-calculator">
                            <div class="row">
                                <h4 class="text-center pb-2 opacity-25">Топливные брикеты</h4>
                                <div class="col-7 col-md-6 px-1">
                                    <div class="form-grp mb-3">
                                        <label class="lh-1 mb-1" for="info-count-package"><small>В упаковке 6 штук:</small></label>
                                        <input style="background: #e9ecef" :value="PACKAGE" id="info-count-package" type="text" required="required" disabled>
                                    </div>
                                </div>
                                <div class="col-5 col-md-6 px-1">
                                    <div class="form-grp mb-3">
                                        <label class="lh-1 mb-1" for="count-package">Кол-во:</label>
                                        <input v-model="form.type_col_package" id="count-package" type="number" name="type-col" min="0" />
                                    </div>
                                </div>
                                <div class="col-7 col-md-6 px-1">
                                    <div class="form-grp mb-4">
                                        <label class="lh-1 mb-1" for="info-count-tray" ><small>В поддоне 96 упаковок:</small></label>
                                        <input style="background: #e9ecef" id="info-count-tray" :value="TRAY" type="text" required="required" disabled>
                                    </div>
                                </div>
                                <div class="col-5 col-md-6 px-1">
                                    <div class="form-grp mb-4">
                                        <label class="lh-1 mb-1" for="count-tray">Кол-во:</label>
                                        <input v-model="form.type_col_tray" id="count-tray" type="number" name="type-col" min="0" />
                                    </div>
                                </div>
                                <h5 class="mb-0 px-2 opacity-75">Стоимость: {{ form.price = (br_package_price * form.type_col_package) + (br_tray_price * form.type_col_tray) }} <span class="text-lowercase">руб.</span></h5>
                            </div>
                        </div>

                        <div class="col-md-12 order-checkout">
                            <div class="row">
                                <h4 class="text-center opacity-25">Оформление заказа</h4>
                                <div class='py row text-center mb-4 px-2'>
                                    <label class="col-6 fw-bold text-uppercase px-1">
                                        <input v-model="form.customer" :value="NP" type="radio" class="option-input radio" name="face" checked />
                                        <small :class="form.customer === NP ? 'text-green' : 'text-muted'">{{NP}}</small>
                                    </label>
                                    <label class="col-6 fw-bold text-uppercase px-1">
                                        <input v-model="form.customer" :value="LP" type="radio" class="option-input radio" name="face" />
                                        <small :class="form.customer === LP ? 'text-green' : 'text-muted'">{{LP}}</small>
                                    </label>
                                </div>
                                <div v-if="form.customer === NP" class="col-md-12 px-1">
                                    <div class="form-grp mb-4">
                                        <label for="full-name" class="lh-1">ФИО <span class="text-danger">*</span></label>
                                        <input v-model="form.fio" type="text" id="full-name" placeholder="ФИО" required="required" data-error="Заполните ФИО">
                                        <div class="help-block with-errors"></div>
                                    </div>
                                </div>
                                <div class="col-md-6 px-1">
                                    <div class="form-grp mb-3">
                                        <label for="phone" class="lh-1">Телефон <span class="text-danger">*</span></label>
                                        <input v-model="form.phone" @input="event => form.phone = event.target.value.replace(/[^0-9+]/g, '')" type="text" id="phone" placeholder="Телефон" required="required" data-error="Заполните Телефон">
                                        <div class="help-block with-errors"></div>
                                    </div>
                                </div>
                                <div class="col-md-6 px-1">
                                    <div class="form-grp mb-3">
                                        <label for="email" class="lh-1">Email <span class="text-danger">*</span></label>
                                        <input v-model="form.email" type="email" id="email" placeholder="Email" required="required" data-error="Заполните Email">
                                        <div class="help-block with-errors"></div>
                                    </div>
                                </div>
                                <div v-if="form.customer === LP" class="col-md-12 px-1">
                                    <div class="form-grp mb-3">
                                        <label for="company" class="lh-1">Название компании <span class="text-danger">*</span></label>
                                        <input v-model="form.name_company" type="text" id="company" placeholder="Название компании" required="required" data-error="Заполните Название компании">
                                        <div class="help-block with-errors"></div>
                                    </div>
                                </div>
                                <div v-if="form.customer === LP" class="col-md-12 px-1">
                                    <div class="form-grp mb-3">
                                        <label class="lh-1">УНП / ОКПО <span class="text-danger">*</span></label>
                                        <input v-model="form.unn" type="text" placeholder="УНП / ОКПО" required="required" data-error="Заполните УНП / ОКПО">
                                        <div class="help-block with-errors"></div>
                                    </div>
                                </div>
                                <div v-if="form.customer === LP" class="col-md-12 px-1">
                                    <div class="form-grp mb-3">
                                        <label class="lh-1">Расчетный счет <span class="text-danger">*</span></label>
                                        <input v-model="form.account" type="text" placeholder="Расчетный счет" required="required" data-error="Заполните Расчетный счет">
                                        <div class="help-block with-errors"></div>
                                    </div>
                                </div>
                                <div v-if="form.customer === LP" class="col-md-12 px-1">
                                    <div class="form-grp mb-3">
                                        <label class="lh-1">Банк, адрес банка, код банка <span class="text-danger">*</span></label>
                                        <textarea v-model="form.bank" name="message" id="message" placeholder="Банк, адрес банка, код банка" required="required" data-error="Заполните Банк, адрес банка, код банка"></textarea>
                                        <div class="help-block with-errors"></div>
                                    </div>
                                </div>
                                <div v-if="form.customer === LP" class="col-md-12 px-1">
                                    <div class="form-grp mb-3">
                                        <label class="lh-1">Руководитель организации и на основании чего действует <span class="text-danger">*</span></label>
                                        <input v-model="form.leader" type="text" placeholder="" required="required" data-error="Заполните">
                                        <div class="help-block with-errors"></div>
                                    </div>
                                </div>
                                <div class="col-md-12 mb-3 px-1">
                                    <a href="/policy" target="_blank" class="d-flex w-100   text-secondary text-decoration-underline">
                                        Политика в отношении обработки персональных данных
                                    </a>
                                </div>
                                <div class="col-md-12 px-1">
                                    <label class="lh-1" for="select-delivery">Способ доставки <span class="text-danger">*</span></label>
                                    <select :disabled="form.price === 0" v-model="form.delivery" class="form-select mb-4" id="select-delivery" required="required" data-error="Выберите необходимое">
                                        <option :value="false">Самовывоз</option>
                                        <option :value="true">Доставка</option>
                                    </select>
                                </div>
                                <div v-if="form.delivery === true" class="col-md-12 px-1">
                                    <div class="form-grp mb-4">
                                        <label for="country" class="lh-1">Страна <span class="text-muted">*</span></label>
                                        <input class="btn-secondary" v-model="form.country" type="text" id="country" required="required" disabled>
                                    </div>
                                </div>
                                <div v-if="form.delivery === true" class="col-md-12 px-1">
                                    <label for="phone" class="lh-1">Город / Населенный пункт <span class="text-danger">*</span></label>
                                    <v-select class="mb-3" v-model="form.city" :options="list_city"
                                              @input="event => filterCity(event.target.value)" label="name">
                                        <template v-slot:no-options="{ search, searching }">
                                            <template v-if="searching">
                                                Ничего не найдено: <em>{{ search }}</em>
                                            </template>
                                            <em v-else style="opacity: 0.5">Введите название города / населенного пункта.</em>
                                        </template>
                                        <template v-slot:option="option">
                                            {{ option.name + ' ('+ option.region + ' обл.' + (option.district ? (', ' + option.district +' р-н)') : ')') }}
                                        </template>
                                    </v-select>
                                </div>
                                <div v-if="form.delivery === true" class="col-md-12 px-1">
                                    <div class="form-grp mb-4">
                                        <label for="address" class="lh-1">Адрес <span class="text-danger">*</span></label>
                                        <input v-model="form.address" type="email" id="address" placeholder="Улица / Дом" required="required" data-error="Заполните Адрес">
                                        <div class="help-block with-errors"></div>
                                    </div>
                                </div>
                                <div v-if="form.delivery === false" class="col-md-12">
                                    <h6 class="text-danger">Самовывоз по адресу:</h6>
                                    <h6 class="opacity-75">г. Заславль, ул. Заводская, 8А.</h6>
                                    <div class="pt-1">
                                        <small class="fw-bold">ВРЕМЯ РАБОТЫ:</small>
                                    </div>
                                    <div class="lh-sm">
                                        <small class="opacity-75">Понедельник — Пятница с 8:30 до 17:00</small>
                                    </div>
                                    <div class="lh-sm">
                                        <small class="opacity-75">Выходной: Суббота — Воскресенье</small>
                                    </div>
                                </div>
                                <h5 v-if="form.city && !!Object.keys(autolight_delivery).length" class="px-2 mb-0 opacity-75"> <!--  && autolight_delivery['price']-->
                                    Стоимость доставки: {{(!!Object.keys(autolight_delivery).length ? autolight_delivery['price'] : 0)}} <span class="text-lowercase">руб.</span>
                                </h5>
                                <div v-else-if="form.city" class="text-muted">
                                    <h5>Внимание! Стоимость доставки не расчитана.</h5>
                                    <div>
                                        {{autolight_delivery_messages}}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <h4 class="pt-4 pb-3 fw-bold text-center text-danger">Итого к оплате: {{form.total_price = (br_package_price * form.type_col_package) + (br_tray_price * form.type_col_tray) + (!!Object.keys(autolight_delivery).length ? autolight_delivery['price'] : 0)}} <span class="text-lowercase">руб.</span></h4>

                        <div v-if="form.delivery === true || form.delivery === false" class="col-md-12 order-pay mb-4">
                            <h4 class="text-center opacity-25">Способ Оплаты заказа</h4>
                            <div class='py'>
                                <label class="fw-bold d-flex" :class="form.payment === WEBPAY_BELARUS ? 'text-green' : 'text-muted'">
                                    <input v-model="form.payment" :value="WEBPAY_BELARUS" type="radio" class="option-input radio" name="payment" />
                                    <div class="pt-1">
                                        ОПЛАТА ОНЛАЙН
                                        <img :src="'assets/img/others/webpay.png'" width="350" alt="card">
                                    </div>
                                </label>
                                <label v-if="!form.delivery" class="fw-bold" :class="form.payment === PAY_ON_RECEIPT ? 'text-green' : 'text-muted'">
                                    <input v-model="form.payment" :value="PAY_ON_RECEIPT" type="radio" class="option-input radio" name="payment" />
                                    {{PAY_ON_RECEIPT}}
                                </label>
                            </div>
                        </div>

                        <div class="pricing__box text-center py-0 px-0" >
                            <div class="pricing-btn offCanvas-btn" >
                                <div v-if="form.payment && form.price !== 0
                                    && (form.customer === NP ? form.fio : form.customer === LP ? form.name_company && form.unn && form.account && form.bank && form.leader : 1)
                                    && form.phone && form.email && form.email.includes('@') && form.delivery !== ''
                                    && ((form.delivery === true && form.city && form.address) || form.delivery === false)">
                                    <a href="javascript:void(0)" @click="createOrder" class="navSidebar-button">
                                        Оформить заказ
                                        <span>Вся информация по вашему заказу будет отправлена на EMAIL.</span>
                                    </a>
                                </div>
                                <div v-else>
                                    <a href="#" class="navSidebar-button bg-white text-secondary border-secondary">
                                        <h5 v-if="form.price === 0" class="fw-bold opacity-75">Выберите нужное количество</h5>
                                        <h5 v-else-if="form.customer === NP && !form.fio" class="fw-bold opacity-75">Заполните ФИО</h5>
                                        <h5 v-else-if="!form.phone" class="fw-bold opacity-75">Заполните телефон</h5>
                                        <h5 v-else-if="!form.email || !form.email.includes('@')" class="fw-bold opacity-75">Заполните email</h5>
                                        <h5 v-else-if="form.customer === LP && !form.name_company" class="fw-bold opacity-75">Заполните Название компании</h5>
                                        <h5 v-else-if="form.customer === LP && !form.unn" class="fw-bold opacity-75">Заполните УНП / ОКПО</h5>
                                        <h5 v-else-if="form.customer === LP && !form.account" class="fw-bold opacity-75">Заполните Расчетный счет</h5>
                                        <h5 v-else-if="form.customer === LP && !form.bank" class="fw-bold opacity-75">Заполните Банк, адрес банка, код банка</h5>
                                        <h5 v-else-if="form.customer === LP && !form.leader" class="fw-bold opacity-75">Заполните Руководитель организации</h5>
                                        <h5 v-else-if="form.delivery === ''" class="fw-bold opacity-75">Выберите способ доставки</h5>
                                        <h5 v-else-if="form.delivery === true && !form.city" class="fw-bold opacity-75">Заполните Город / Населенный пункт</h5>
                                        <h5 v-else-if="form.delivery === true && !form.address" class="fw-bold opacity-75">Заполните Адрес / Дом</h5>
                                        <h5 v-else class="fw-bold opacity-75">Выберите способ оплаты</h5>
                                        <span>Вся информация по заказу будет отправлена на EMAIL.</span>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>

                    </form>
                </div>
            </div>
        </div>
        <div class="offCanvas-overlay"></div>
        <!-- offCanvas-end -->
    </header>
    <!-- header-area-end -->

    <!-- main-area -->
    <main class="main-area fix">
        <!-- tg-banner-area -->
        <section  class="tg-banner-area banner-bg jarallax parallax-img">
            <div class="container">
                <div class="row align-items-center justify-content-center">
                    <div class="col-xl-6 col-lg-6 order-0 order-lg-2">
                        <div class="tg-banner-img text-center wow fadeInRight" data-wow-delay=".25s">
                            <img :src="'assets/img/products/briket.webp'" height="370" alt="img">
                        </div>
                    </div>
                    <div class="col-xl-6 col-lg-6 col-md-10">
                        <div class="tg-banner-content">
                            <h4 class="sub-title wow fadeInUp" data-wow-delay=".1s">ЭКОЛОГИЧЕСКИ ЧИСТЫЙ ВИД ТОПЛИВА!</h4>
                            <h1 class="title wow fadeInUp mb-3 topbriket" data-wow-delay=".2s"> ТОПЛИВНЫЕ БРИКЕТЫ</h1>
<!--                            <h4 class="wow fadeInUp fw-bold text-yellow pb-2 promotion" data-wow-delay=".3s">АКЦИЯ! <br>БЕСПЛАТНАЯ доставка от одного поддона-->
<!--                                <br>Минская область (включая г.Минск)</h4>-->
                            <div class="tg-banner-btn wow fadeInUp" data-wow-delay=".5s">
                                <a href="#shop" @click="formTop" class="section-link tg-btn navSidebar-button">Сделать заказ</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <!-- banner-area-end -->

        <!-- tg-features-area -->
        <section class="tg-features-area">
            <div class="container">
                <div class="tg-features-inner">
                    <div class="row justify-content-center">
                        <div class="col-lg-4 col-md-6 col-sm-8 wow fadeInUp" data-wow-delay=".2s">
                            <div class="tg-features-item">
                                <div class="tg-features-icon">
                                    <div class="fw-bold">1</div>
                                </div>
                                <div class="tg-features-content">
                                    <h4 class="title">Шаг 1. Оформление заказа</h4>
                                    <p>Вам необходимо оформить заказ на нашем сайте.</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6 col-sm-8 wow fadeInUp" data-wow-delay=".4s">
                            <div class="tg-features-item">
                                <div class="tg-features-icon">
                                    <div class="fw-bold">2</div>
                                </div>
                                <div class="tg-features-content">
                                    <h4 class="title">Шаг 2. Выбор способа оплаты</h4>
                                    <p>С вами свяжется наш специалист для уточнения заказа и оплаты.</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6 col-sm-8 wow fadeInUp" data-wow-delay=".6s">
                            <div class="tg-features-item">
                                <div class="tg-features-icon">
                                    <div class="fw-bold">3</div>
                                </div>
                                <div class="tg-features-content">
                                    <h4 class="title">Шаг 3. Самовывоз / Доставка</h4>
                                    <p>Самовывоз либо мы доставим ваш заказ в кратчайшие сроки.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <!-- tg-features-area-end -->

        <!-- service-area -->
        <section id="benefits" class="tg-service-area pt-5">
            <div class="container">
                <div class="tg-service-inner">
                    <div class="row justify-content-center">
                        <div class="col-lg-8">
                            <div class="tg-section-title text-center mb-60">
                                <span class="sub-title">БРИКЕТЫ</span>
                                <h2 class="title">ПРЕИМУЩЕСТВА</h2>
                            </div>
                        </div>
                    </div>
                    <div class="row justify-content-center">
                        <div class="col-lg-4 col-md-6 col-sm-9 px-2">
                            <div class="tg-service-item">
                                <div class="tg-services-count">01</div>
                                <div class="icon">
                                    <span class="fw-bold">✔</span>
                                </div>
                                <h2 class="title">Теплота сгорания</h2>
                                <div class="tg-service-content">
                                    <p>Более 17,0 <span style="font-size: 14px"> МДж/кг</span>.
                                        В 1,5 раза больше, чем у обычных дров. Чем выше теплота сгорания, тем меньше его расход</p>
<!--                                    <p>Брикеты максимально прогорают, хорошая теплотворная способность. Низкая теплота сгорания > 16,9<span style="font-size: 14px"> МДж/кг</span></p>-->
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-6 col-sm-9 px-2">
                            <div class="tg-service-item">
                                <div class="tg-services-count">02</div>
                                <div class="icon">
                                    <span class="fw-bold">✔</span>
                                </div>
                                <h2 class="title">Низкая зольность</h2>
                                <div class="tg-service-content">
                                    <p>Затраты на чистку и обслуживание котлов и печей уменьшаются, а срок службы оборудования увеличивается</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6 col-sm-9 px-2">
                            <div class="tg-service-item">
                                <div class="tg-services-count">03</div>
                                <div class="icon">
                                    <span class="fw-bold">✔</span>
                                </div>
                                <h2 class="title">Компактность</h2>
                                <div class="tg-service-content">
                                    <p>Меньше места для складирования и перевозки, снижаются затраты на транспортировку и хранение</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6 col-sm-9 px-2">
                            <div class="tg-service-item">
                                <div class="tg-services-count">04</div>
                                <div class="icon">
                                    <span class="fw-bold">✔</span>
                                </div>
                                <h2 class="title">Безопасность</h2>
                                <div class="tg-service-content">
                                    <p>При сжигании данного вида топлива не выделяется угарный газ и вредные для здоровья соединения</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6 col-sm-9 px-2">
                            <div class="tg-service-item">
                                <div class="tg-services-count">05</div>
                                <div class="icon">
                                    <span class="fw-bold">✔</span>
                                </div>
                                <h2 class="title">Экологичность</h2>
                                <div class="tg-service-content">
                                    <p>Не вредят здоровью человека и окружающей среде. <br> Максимально прогорают</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6 col-sm-9 px-2">
                            <div class="tg-service-item">
                                <div class="tg-services-count">06</div>
                                <div class="icon">
                                    <span class="fw-bold">✔</span>
                                </div>
                                <h2 class="title">Высокая плотность</h2>
                                <div class="tg-service-content">
                                    <p>Обеспечивается длительное горение, поэтому их необходимо в 3-4 раза реже подкладывать в котел или печь</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <!-- service-area-end -->

        <!-- video-area -->
        <section id="production" class="tg-video-area tg-video-bg jarallax parallax-img">
            <div class="container">
                <div class="row align-items-center justify-content-center">
                    <div class="col-xl-7 col-lg-8">
                        <div class="tg-video-img wow fadeInLeft" data-wow-delay=".2s">
                            <img style="opacity: .87" :src="'assets/img/others/coswick-video.jpg'" alt="">
                            <a href="https://player.vimeo.com/video/383489437?byline=0&color=cc2200" class="play-btn popup-video" aria-label="Безотходное производство Coswick видео">
                                <i class="fas fa-play"></i></a>
                        </div>
                    </div>
                    <div class="col-xl-5 col-lg-8">
                        <div class="tg-video-content wow fadeInRight" data-wow-delay=".2s">
                            <div class="tg-section-title white mb-30">
                                <span class="sub-title">Производство</span>
<!--                                <h2 class="title white-text">Как происходит процесс создания топливных брикетов</h2>-->
                                <h2 class="title white-text">Безотходное Производство COSWICK</h2>
                            </div>
                            <p class="info-one">Топливные брикеты могут применяться в самых разных котлах, в домашних и производственных печах, дачных и жилых каминах, банях и саунах, для приготовления шашлыков и барбекю.</p>
                            <p class="info-two"></p>
                            <a href="#shop" @click="formTop" class="section-link tg-btn navSidebar-button">Сделать заказ</a>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <!-- video-area-end -->

        <!-- features-product -->
        <section id="shop" class="features-products pt-5">
            <div  class="container">
                <div class="row justify-content-center">
                    <div class="col-xl-6 col-lg-8">
                        <div class="tg-section-title text-center mb-70">
                            <span class="sub-title">Продукция</span>
                            <h2 class="title">Топливные брикеты</h2>
                            <p> Основным материалом для производства топливных брикетов являются сухие опилки и щепа древесины дуба и березы.</p>
                        </div>
                    </div>
                </div>
                <div class="features-products-wrap">
                    <div class="row justify-content-center">
                        <div class="col-lg-6 col-md-8">
                            <div class="features-products-thumb m-4">
                                <div class="main-img">
                                    <img :src="'assets/img/products/briket-2.webp'" alt="img">
                                </div>
<!--                                <img src="assets/img/products/features_product_shape01.png" alt="img" class="shape-img">-->
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-10">
                            <div class="features-product-content">
                                <h2 class="title">Брикеты (УПАКОВКА)</h2>
<!--                                <h6 class="features-product-quantity"></h6>-->
                                <div class="inner-shop-details-list">
                                    <ul class="list-wrap text-start">
                                        <li>Размеры : <span>Ø 85 * 275 мм</span></li>
                                        <li>Порода древесины : <span>Смешанная (дуб, береза)</span></li>
                                        <li>Сорт : <span>Сорт 1</span></li>
                                        <li>Применение : <span>В установках, работающих на твердом топливе</span></li>
                                        <li>Низшая теплота сгорания : <span>не менее 16,9 МДж/кг</span></li>
                                        <li>Зольность : <span>не более 0,7 %</span></li>
                                        <li>Массовая доля общей влаги : <span>не более 10,0 %</span></li>
                                        <li>Упаковка : <span>Термоусадочная плёнка</span></li>
                                        <li>Штук в упаковке : <span>6</span></li>
                                        <li>Масса : <span>10 кг</span></li>
                                        <li>Гарантийный срок : <span>12 месяцев</span></li>
                                    </ul>
                                </div>
                                <div class="features-product-bottom offCanvas-btn">
                                    <span class="price ms-0 me-4">{{br_package_price}} руб. <small class="fs-5 text-muted">/ 10 кг</small><span v-if="br_package_price < 4" class="old-price">4 руб.</span></span>
                                    <div @click="formTop" class="btn text-uppercase navSidebar-button">Сделать заказ</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="features-products-wrap">
                    <div class="row justify-content-center">
                        <div class="col-lg-6 col-md-8">
                            <div class="features-products-thumb m-1">
                                <div class="main-img">
                                    <img :src="'assets/img/products/briket.webp'" alt="img">
                                </div>
<!--                                <img src="assets/img/products/features_product_shape02.png" alt="img" class="shape-img">-->
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-10">
                            <div class="features-product-content">
                                <h2 class="title">Брикеты (Поддон)</h2>
                                <div class="inner-shop-details-list">
                                    <ul class="list-wrap text-start">
                                        <li>Размеры : <span>Ø 85 * 275 мм</span></li>
                                        <li>Порода древесины : <span>Смешанная (дуб, береза)</span></li>
                                        <li>Сорт : <span>Сорт 1</span></li>
                                        <li>Применение : <span>В установках, работающих на твердом топливе</span></li>
                                        <li>Низшая теплота сгорания : <span>не менее 16,9 МДж/кг</span></li>
                                        <li>Зольность : <span>не более 0,7 %</span></li>
                                        <li>Массовая доля общей влаги : <span>не более 10,0 %</span></li>
                                        <li>Упаковка : <span>Термоусадочная плёнка</span></li>
                                        <li>Упаковок в поддоне : <span>96</span></li>
                                        <li>Гарантийный срок : <span>12 месяцев</span></li>
                                        <li>Размер поддона, В×Ш×Д : <span>1265×1000×1200 мм</span></li>
                                    </ul>
                                </div>
                                <div class="features-product-bottom offCanvas-btn">
                                    <span class="price ms-0 me-4">{{br_tray_price}} руб. <small class="fs-5 text-muted">/ 960 кг</small><span class="old-price">576 руб.</span></span>
                                    <div @click="formTop" class="btn text-uppercase navSidebar-button">Сделать заказ</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="fp-shapes-wrap">
                <div class="fp-shape-one">
                    <img :src="'assets/img/others/features_sec_shape01.png'" alt="shape" class="paroller" data-paroller-factor="0.25" data-paroller-factor-lg="0.20" data-paroller-factor-md="0.25" data-paroller-factor-sm="0.10" data-paroller-type="foreground" data-paroller-direction="vertical">
                </div>
                <div class="fp-shape-two">
                    <img :src="'assets/img/others/features_sec_shape02.png'" alt="shape" class="paroller" data-paroller-factor="-0.25" data-paroller-factor-lg="0.20" data-paroller-factor-md="0.25" data-paroller-factor-sm="0.10" data-paroller-type="foreground" data-paroller-direction="vertical">
                </div>
                <div class="fp-shape-three">
                    <img :src="'assets/img/others/features_sec_shape03.png'" alt="shape" class="paroller" data-paroller-factor="0.25" data-paroller-factor-lg="0.20" data-paroller-factor-md="0.25" data-paroller-factor-sm="0.10" data-paroller-type="foreground" data-paroller-direction="vertical">
                </div>
            </div>
            <div class="fp-circle one"></div>
            <div class="fp-circle two"></div>
            <div class="fp-circle three"></div>
            <div class="fp-circle four"></div>
            <div class="fp-circle five"></div>
        </section>
        <!-- features-product-end -->

        <!-- testimonial-area -->
        <section id="reviews" class="testimonial-area testimonial-bg" data-background="assets/img/bg/reviews.jpg">
            <div class="testimonial-overlay"></div>
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-xxl-8 col-xl-9 col-lg-11">
                        <div class="testimonial-active">
                            <div class="testimonial-item text-center">
                                <div class="testimonial-rating">
                                    <i class="fas fa-star"></i>
                                    <i class="fas fa-star"></i>
                                    <i class="fas fa-star"></i>
                                    <i class="fas fa-star"></i>
                                    <i class="fas fa-star"></i>
                                </div>
                                <p>“Мы покупаем их для топки баньки.
                                    Очень часто выезжаем на природу с нашей любимой мобильной банькой, но не везде есть возможность набрать хворост или нарубить дров, даже если есть лес... Поэтому такие брикеты покупаем постоянно и помногу."</p>
                                <div class="testimonial-avatar-wrap">
                                    <div class="testi-avatar-img">
                                        <img :src="'assets/img/others/prifil.jpg'" alt="img">
                                    </div>
                                    <div class="testi-avatar-info">
                                        <h6 class="name">Дмитрий</h6>
                                    </div>
                                </div>
                            </div>
                            <div class="testimonial-item text-center">
                                <div class="testimonial-rating">
                                    <i class="fas fa-star"></i>
                                    <i class="fas fa-star"></i>
                                    <i class="fas fa-star"></i>
                                    <i class="fas fa-star"></i>
                                    <i class="fas fa-star"></i>
<!--                                    <i class="fas fa-star-half-alt"></i>-->
                                </div>
                                <p>“По мне отличная замена обыкновенным дровам.
                                    Теплоотдача и это не просто слова на сайте (кстати, я им просто не поверил первоначально), но после использования мое мнение резко изменилось в лучшую сторону."</p>
                                <div class="testimonial-avatar-wrap">
                                    <div class="testi-avatar-img">
                                        <img :src="'assets/img/others/prifil.jpg'" alt="img">
                                    </div>
                                    <div class="testi-avatar-info">
                                        <h6 class="name">Александр</h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <!-- testimonial-area-end -->


        <!-- blog-post-area -->
        <section id="delivery" class="blog-post-area">
            <div class="container">
                <div class="blog-inner-wrapper">
                    <div class="row justify-content-center">
                        <div class="col-lg-4 col-md-10 text-center mb-4 mb-sm-0">
                            <a :href="'assets/img/others/Свидетельство о регистрации Косвик.webp'" target="_blank">
                                <img :src="'assets/img/others/Свидетельство о регистрации Косвик.webp'" alt="Свидетельство о регистрации Косвик" />
                            </a>
                        </div>
                        <div class="col-lg-8 col-md-10">
                            <div class="faq-wrapper">
                                <div class="section-title mb-2">
                                    <h2 class="title">Информация</h2>
                                </div>
                                <div class="accordion" id="accordionExample">
                                    <div class="accordion-item active-item">
                                        <h6 class="accordion-header" id="headingOne">
                                            <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                                <span class="count">01.</span> Процедура оформления заказа
                                            </button>
                                        </h6>
                                        <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne"
                                             data-bs-parent="#accordionExample">
                                            <div class="accordion-body">
                                                <h6 class="mb-1 lh-sm text-muted" style="text-transform: none">
                                                    Нажимаем на кнопку сделать заказ
                                                    ⇨ Выбираем нужное кол-во товара
                                                    ⇨ Вводим данные покупателя
                                                    ⇨ Выбираем способ доставки
                                                    ⇨ Выбираем способ оплаты
                                                    ⇨ Нажимаем на кнопку "Оформить заказ".</h6>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="accordion-item">
                                        <h6 class="accordion-header" id="headingTwo">
                                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                                    data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseOne">
                                                <span class="count">02.</span> Способы оплаты товара
                                            </button>
                                        </h6>
                                        <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                                            <div class="accordion-body opacity-75">
                                                <h6 style="text-transform: none">— Банковскими платежными картами онлайн;</h6>
                                                <div class="information-min">
                                                    <span class="ps-3">Для</span> всех типов карт используется перенаправление в систему <a :href="'//www.webpay.by'" target="_blank" class="text-primary">WEBPAY</a>. Безопасный сервер WEBPAY устанавливает шифрованное соединение по защищенному протоколу TLS и конфиденциально принимает от клиента данные его платёжной карты (номер карты, имя держателя, дату окончания действия, и контрольный номер банковской карточке CVC/CVC2).
                                                </div>
                                                <div class="information-min">
                                                    <span class="ps-3">После</span> совершения оплаты с использованием банковской карты необходимо сохранять полученные карт-чеки (подтверждения об оплате) для сверки с выпиской из карт-счёта (с целью подтверждения совершённых операций в случае возникновения спорных ситуаций).
                                                </div>
                                                <div class="information-min">
                                                    <span class="ps-3">В</span> случае, если Вы не получили заказ (не оказана услуга), Вам необходимо обратиться (в службу технической поддержки) по телефонам +375(17)543-20-30, +375 (29) 251-60-79 или отправить сообщение на электронную почту smolyagaas@coswick.org. Менеджеры Вас проконсультируют.
                                                </div>
                                                <div class="information-min">
                                                    <span class="ps-3">При</span> оплате банковской платежной картой возврат денежных средств осуществляется на карточку, с которой была произведена оплата".
                                                </div>
                                                <img class="pt-2 pb-3" :src="'assets/img/others/webpay.png'" alt="card">
                                                <h6 style="text-transform: none">— Наличными денежными средствами в момент получения товара;</h6>
                                                <h6 style="text-transform: none">— Банковскими платежными картами в момент получения товара;</h6>
                                                <h6 style="text-transform: none">— Через ЕРИП.</h6>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="accordion-item">
                                        <h6 class="accordion-header" id="headingFree">
                                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                                    data-bs-target="#collapseFree" aria-expanded="false" aria-controls="collapseFree">
                                                <span class="count">03.</span> Доставка / Самовывоз товара
                                            </button>
                                        </h6>
                                        <div id="collapseFree" class="accordion-collapse collapse" aria-labelledby="headingFree"
                                             data-bs-parent="#accordionExample">
                                            <div class="accordion-body ">
                                                <h6 class="text-muted" style="text-transform: none">Доставка осуществляется транспортной компанией Автолайтэкспресс.</h6>
                                                <h6 class="text-muted" style="text-transform: none">Стоимость услуг по перевозке товаров определяется согласно тарифов транспортных компаний. </h6>

                                                <h6 class="pt-2 fw-bold opacity-75">Для заказа необходимо сообщить менеджеру:</h6>
                                                <h6 class="text-muted" style="text-transform: none">— Ф.И.О.;</h6>
                                                <h6 class="text-muted" style="text-transform: none">— Контактный номер телефона;</h6>
                                                <h6 class="mb-0 text-muted" style="text-transform: none">— Город \ Адрес.</h6>

                                                <h6 class="pt-3 fw-bold opacity-75">Самовывоз доступен по адресу:</h6>
                                                <h6 class="text-muted" style="text-transform: none">г.Заславль, ул.Заводская, 8А, Беларусь, Минская область, Минский район</h6>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="accordion-item">
                                        <h6 class="accordion-header" id="headingFour">
                                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                                    data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                                <span class="count">04.</span> Образец платежных документов
                                            </button>
                                        </h6>
                                        <div id="collapseFour" class="accordion-collapse collapse" aria-labelledby="headingFour"
                                             data-bs-parent="#accordionExample">
                                            <div class="accordion-body">
                                                <div class="rc-post">
                                                    <div class="rc-post-content pe-2">
                                                        <h5 class="text-muted">Образец платежных документов, формируемых с использованием кассового оборудования, подтверждающих прием продавцом от покупателя наличных денежных средств.</h5>
                                                    </div>
                                                    <div>
                                                        <a :href="'/assets/img/others/check.jpg'" target="_blank"><img :src="'/assets/img/others/check.jpg'" width="700" alt="img"></a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="accordion-item">
                                        <h6 class="accordion-header" id="headingFive">
                                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                                    data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                                                <span class="count">05.</span>Возврат качественного / некачественного товара
                                            </button>
                                        </h6>
                                        <div id="collapseFive" class="accordion-collapse collapse" aria-labelledby="headingFive"
                                             data-bs-parent="#accordionExample">
                                            <div class="accordion-body">
                                                <h6 class="text-muted lh-base pb-2" style="text-transform: none">
                                                    При возникновении гарантийного случая, наличии претензии по качеству или комплектности приобретенной продукции просим вас отправить сообщение на электронную почту smolyagaas@coswick.org.
                                                    Менеджер подскажет вам, как пошагово оформить возврат.
                                                </h6>
                                                <div>
                                                    <h6 class="mb-1 fw-bold opacity-75">Условия возврата и обмена</h6>
                                                    <h6 class="text-muted mb-1" style="text-transform: none">Компания осуществляет возврат и обмен этого товара в соответствии с требованиями законодательства.</h6>
                                                    <h6 class="text-muted mb-1" style="text-transform: none">ЗАКОН РЕСПУБЛИКИ БЕЛАРУСЬ от 9 января 2002 г. N 90-З «О защите прав потребителей»</h6>
                                                    <small class="d-flex lh-1 pb-3">в редакции от 13 июня 2018 г. № 111-З</small>
                                                    <h6 class="mb-1 fw-bold opacity-75">Сроки возврата</h6>
                                                    <h6 class="text-muted" style="text-transform: none">Возврат возможен в течение 14 дней после получения (для товаров надлежащего качества). Обратная доставка товаров осуществляется по договоренности.Согласно действующему законодательству вы можете вернуть товар надлежащего качества или обменять его, если:</h6>
                                                    <h6 class="text-muted" style="text-transform: none">— товар полностью укомплектован и сохранена фабричная упаковка;</h6>
                                                    <h6 class="text-muted" style="text-transform: none">— сохранена заводская маркировка;</h6>
                                                    <h6 class="text-muted" style="text-transform: none">— товар сохраняет товарный вид и свои потребительские свойства.</h6>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="accordion-item">
                                        <h6 class="accordion-header" id="headingSix">
                                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                                    data-bs-target="#collapseSix" aria-expanded="false" aria-controls="collapseSix">
                                                <span class="count">06.</span> Книга замечаний и предложений
                                            </button>
                                        </h6>
                                        <div id="collapseSix" class="accordion-collapse collapse" aria-labelledby="headingSix"
                                             data-bs-parent="#accordionExample">
                                            <div class="accordion-body">
                                            <h6 class="text-muted lh-base" style="text-transform: none">Книга замечаний и предложений находиться по адресу: улица Якуба Коласа, 2Б, Заславль, Отдел продаж.</h6>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <!-- blog-post-area-end -->
    </main>
    <!-- main-area-end -->

    <!-- Footer-area -->
    <footer class="footer-style-two">
        <div class="footer-two-top-wrap">
            <div class="container">
                <div class="footer-two-widgets-wrap">
                    <div class="row justify-content-between">
                        <div class="col-xl-6 col-lg-4 col-md-7 col-sm-9">
                            <div class="footer-widget">
                                <div class="footer-about">
                                    <div class="footer-logo logo">
                                        <h6 class="fw-bold text-white fs-3">TOP BRIKET <span class="text-lowercase fs-4">by</span> <span class="fs-4">Coswick</span></h6>
                                    </div>
                                    <div class="footer-text">
                                        <div>
                                            <h6 class="text-white">ООО «Косвик», зарегистрировано Миноблисполкомом 03.01.2023 в ЕГР за № 101040134</h6>
                                        </div>
                                        <div class="text-white">
                                            <h6 class="text-white">Дата включения сведений в торговый реестр: 1-11-2023</h6>
                                        </div>
                                        <div>
                                            <small>Уполномоченный рассматривать обращения покупателей о нарушении их прав, предусмотренных законодательством
                                                о защите прав потребителей, <span class="pe-1">телефон</span><a href="tel:+375336046557">+375<span class="px-1">33</span>604-65-57</a>,
                                                <span class="pe-1">email:</span><a href="mailto:sytinvv@coswick.org" target="_blank">sytinvv@coswick.org</a></small>
                                        </div>
                                        <div>
                                            <small>График работы уполномоченного: понедельник - пятница: с 08:30 до 17:00</small>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-3 col-lg-3 col-md-5 col-sm-4">
                            <div class="footer-widget widget_nav_menu px-1">
                                <h6 class="fw-title">Адрес <span class="text-muted">/ График работы</span></h6>
                                <h6 class="text-white pb-2">г.Заславль, ул.Заводская, 8А</h6>
                                <h6 class="text-muted">Понедельник — Пятница с 8:30 до 17:00</h6>
                                <h6 class="text-muted">Выходной: Суббота — Воскресенье</h6>
                            </div>
                        </div>

                        <div class="col-xl-3 col-lg-4 col-md-5">
                            <div class="footer-widget">
                                <h6 class="fw-title">Контакты</h6>
                                <div class="footer-contact-wrap lh-sm">
                                    <ul class="list-wrap">
                                        <li class="phone"><i class="fas fa-phone"></i> <a href="tel:+375175432030" class="opacity-100">+375 (17) 543-20-30</a></li>
                                        <li class="phone"><i class="fas fa-phone"></i> <a href="tel:+375293538931" class="opacity-100">+375 (29) 353-89-31</a></li>
                                        <li class="website"><i class="fas fa-globe"></i> smolyagaas@coswick.org</li>
                                    </ul>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="copyright-wrap">
            <div class="container">
                <div class="row">
                    <div class="row align-items-center">
                        <div class="col-md-7">
                            <div class="copyright-text">
                                <p>Copyright © {{new Date().getFullYear()}} Все права защищены.</p>
                            </div>
                        </div>
                        <div class="col-md-5">
                            <div class="payment-card text-center text-md-end">
                                <img :src="'assets/img/others/webpay.png'" alt="card">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </footer>
    <!-- Footer-area-end -->
</template>
<script>
const NP = 'Физическое лицо';
const LP = 'Юридическое лицо';
const PACKAGE = 'Упаковка';
const TRAY = 'Поддон';

const PAY_ON_RECEIPT = 'ОПЛАТА ПРИ ПОЛУЧЕНИИ';
const WEBPAY_BELARUS = 'WEBPAY BELARUS';

// import VSelect from "vue-select";
import "vue-select/dist/vue-select.css";

export default {
    name: "LandingPageComponent",
    props:['br_package_price','br_tray_price'],
    // components:{VSelect},
    data:() => ({
        NP:NP,
        LP:LP,

        PACKAGE:PACKAGE,
        TRAY:TRAY,

        PAY_ON_RECEIPT:PAY_ON_RECEIPT,
        WEBPAY_BELARUS:WEBPAY_BELARUS,

        list_city_all:[],
        list_city:[],

        count: 1,

        form:{
            customer: NP,

            // type: PACKAGE,
            type_col_package: null,
            type_col_tray: null,
            price: 0,

            fio: '',
            phone: '',
            email: '',

            name_company: '',
            unn: '',
            account: '',
            bank: '',
            leader: '',

            delivery: '',
            country: 'Беларусь',
            city: '',
            address: '',

            payment:'',
            total_price: 0,
        },

        autolight_delivery:[],
        autolight_delivery_messages:'',
    }),
    watch:{
        'form.customer': function (val,oldVal){
            if(val === this.NP){
                this.form.name_company = ''
                    this.form.unn = ''
                    this.form.account = ''
                    this.form.bank = ''
                    this.form.leader = ''
            } else if(val === this.LP) {
                this.form.fio = ''
            }
        },
        'form.delivery': function (val, oldVal){
            this.form.payment = val ? this.WEBPAY_BELARUS : ''
            this.form.city = ''
        },
        'form.city':function (val, oldVal){
            if(!val){
                this.list_city = []
                this.autolight_delivery = []
            }else{
                this.searchDeliveryPrice()
            }
        },
        'form.price':function(val,oldVal){
            this.form.delivery = ''
            this.form.city = ''
        }
    },
    mounted() {
        $('#preloader').delay(0).fadeOut();
        this.searchCity()
    },
    methods:{
        formTop(){
            document.getElementById("createdForm").scrollTo(0, 0);
        },

        searchCity(){
          axios.post('/search-city').then(response => {
              this.list_city_all = response.data
          })
        },

        filterCity(val){
          this.list_city = val.length > 2 ? this.list_city_all.filter(x => x.name.toLowerCase().includes(val.toLowerCase())) : []
        },

        searchDeliveryPrice(){
            axios.post('/search-delivery-price', this.form).then(response => {
                if (response.data['resultCode'] !== 0){
                    this.autolight_delivery_messages = response.data['messages'][0]
                }else{
                    this.autolight_delivery = response.data['result']
                    // if(this.form.type_col_package >= 96 || this.form.type_col_tray > 0){
                    //     if(this.form.city.region === 'Минск' || this.form.city.region === 'Минская'){
                    //         this.autolight_delivery.price = 0
                    //     }
                    // }else{
                    //Доставка скидка
                    //     if(this.form.city.code === '15249' || this.form.city.code === '23018'){
                    //         this.autolight_delivery.price = 50
                    //     }
                    // }
                }
            })
        },

        createOrder(){
            if (this.validateForm()){
                $('#preloader').delay(0).fadeIn();
                this.form['br_package_price'] = this.br_package_price
                this.form['br_tray_price'] = this.br_tray_price
                axios.post('/create-order',this.form).then(response => {
                    if(this.form.payment === this.WEBPAY_BELARUS){
                        window.location.assign(response.data['data']['redirectUrl']);
                    }
                    Object.assign(this.$data.form, this.$options.data().form)
                    this.autolight_delivery = []
                    this.$refs.form_order.click()
                }).finally(() => {$('#preloader').delay(0).fadeOut()})
            }
        },

        validateForm(){
            return !!((this.form.type_col_package || this.form.type_col_tray)
                && (this.form.customer === this.NP ? this.form.fio
                    : this.form.customer === this.LP ? (this.form.name_company && this.form.unn && this.form.account && this.form.bank && this.form.leader)
                    : 1)
                && this.form.phone && this.form.email && this.form.email.includes('@') && (this.form.delivery === true || this.form.delivery === false) && this.form.payment
                && (this.form.delivery === true ? (this.form.city && this.form.address) : 1));
        }
    }
}
</script>

<style scoped>
.order-calculator{
    padding: 23px 30px !important;
    border:1px dashed rgba(114, 128, 150, .5);
    border-radius: 10px;
}
.order-checkout{
    padding: 23px 30px !important;
    border:1px dashed rgba(114, 128, 150, .5);
    border-radius: 10px;
}
.order-pay{
    padding: 23px 30px !important;
    border:1px dashed rgba(114, 128, 150, .5);
    border-radius: 10px;
}
.opacity-60{
    opacity: .6;
}

.text-green{
    color: #0D9B4D;
}

.option-input {
    -webkit-appearance: none;
    -moz-appearance: none;
    -ms-appearance: none;
    -o-appearance: none;
    appearance: none;
    position: relative;
    top: 13.33333px;
    right: 0;
    bottom: 0;
    left: 0;
    height: 35px;
    width: 35px;
    transition: all 0.15s ease-out 0s;
    background: #cbd1d8;
    border: none;
    color: #fff;
    cursor: pointer;
    display: inline-block;
    margin-right: 0.5rem;
    outline: none;
    position: relative;
    z-index: 1000;
    padding: 11px 15px ;
}
.option-input:hover {
    background: #9faab7;
}
.option-input:checked {
    background: linear-gradient(to right, #94be26 0%, #65b021 50%, #94be26 100%);
}
.option-input:checked::before {
    width: 6px;
    height: 15px;
    display:flex;
    content: '\f00c';
    font-size: 16px;
    font-weight:bold;
    position: absolute;
    align-items:center;
    justify-content:center;
    font-family:'Font Awesome 5 Free';
}
.option-input:checked::after {
    -webkit-animation: click-wave 0.65s;
    -moz-animation: click-wave 0.65s;
    animation: click-wave 0.65s;
    background: linear-gradient(to right, #94be26 0%, #65b021 50%, #94be26 100%);
    content: '';
    display: block;
    position: relative;
    z-index: 100;
}
.option-input.radio {
    border-radius: 50%;
}
.option-input.radio::after {
    border-radius: 50%;
}

@keyframes click-wave {
    0% {
        height: 40px;
        width: 40px;
        opacity: 0.35;
        position: relative;
    }
    100% {
        height: 100px;
        width: 100px;
        margin-left: -56px;
        margin-top: -54px;
        opacity: 0;
    }
}

.information-min{
    text-align: justify;
    font-size: 13px;
}

.fs-14{
    font-size: 14px;
}
.text-yellow{
    color: yellow;
    opacity: .95;
    /*-webkit-text-stroke: .1px black;*/
}

@media (max-width: 450px) {
    .promotion{
        font-size: 17px;
    }
    .topbriket{
        font-size: 38px;
    }
}

</style>
